import React, { useEffect, useState } from 'react'
import Header from '../../header/Header'
import Footer from '../../footer'
import rec from '../../../assets/image/rec.png'
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap'
import './toyotaStyle.css'
import logoblue from '../../../assets/image/blueimage.png'
import logoReacrt from '../../../assets/image/rectangle-new.png'
import Home from '../../../assets/image/Home-Banner.png'
import KintoSmall from "../../../assets/image/KintoSmall.png";
import ToyotaSpektakulerSmall from "../../../assets/image/ToyotaSpektakulerSmall.png";

import Toss from '../../../assets/image/TOSS_1.png.png'
import Reacta from '../../../assets/image/Rectangle 4.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL, Token, BASE_URL2, Token2 } from '../../../Config'
import axios from "axios";
import mabileBanner from '../../../assets/image/spectaBanner_mobile-2.png'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import EZDealSmall from "../../../assets/image/EZDealSmall.png";




export default function Toyotaspec() {

  const [data, setData] = useState()
  const [showButton, setShowButton] = useState(false);


  useEffect(() => {

    const handleScroll = () => {
      setShowButton(window.scrollY > 300);
    };


    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    // Smoothly scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });

  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs once when the component mounts



  const [Fname, setFname] = useState('')
  const [Lname, setLname] = useState('')
  const [cellphone, setCellPhone] = useState('')
  const [email, setEmail] = useState('')
  const [city, setCity] = useState('')
  const [dealer, setDealer] = useState('')
  const [Source, setSource] = useState("");

  const [Cities, setCities] = useState([])
  const [alldealer, setAllDealer] = useState([])


  // const handleCityChange = (e) => {
  //     const selectedCityId = e.target.value;

  //     const CITY = JSON.parse(selectedCityId)
  //     setCity(CITY?.id)
  //     handleDealers(CITY?.id)

  // };



  // const handleDealerChange = (e) => {
  //     const selectedCityId = e.target.value;
  // };



  const handleSelectChange = (e) => {
    // Your existing logic for handling select change
    setDealer(e.target.value);

  };

  const handleCityChange = (e) => {


    setCity(e.target.value)
    handleDealers(e.target.value)
  }




  const handleDealers = async (CITY) => {
    try {


      const response = await fetch(`${BASE_URL2}api/v1/dealers?city_id=${CITY}`, {
        headers: {

          Authorization: `Bearer ${Token2}`,


        }
      });
      const data = await response.json();
      console.log("published/daelers", data);

      setAllDealer(data)






    } catch (error) {
      console.error('Error fetching buyer data:', error);
    }

  }





  useEffect(() => {
    handleCities()

  }, [])




  const handleCities = async () => {
    try {


      const response = await fetch(`${BASE_URL2}api/v1/cities`, {
        headers: {

          Authorization: `Bearer ${Token2}`,


        }
      });
      const data = await response.json();
      console.log("published/Cities", data);
      setCities(data)






    } catch (error) {
      console.error('Error fetching buyer data:', error);
    }

  }





  const handleSubmitToyota = (e) => {
    e.preventDefault()
    console.log("formdara", Lname, Fname, city, cellphone, dealer, email)

    try {

      let fdata = new FormData();

      fdata.append('name', `${Fname} ${Lname}`);
      fdata.append('email', email);
      fdata.append('phone', cellphone);
      fdata.append('city_id', city);
      fdata.append('dealer_id', dealer);
      fdata.append('source', "WebMobile-ToyotaSpektakuler");


      fetch(`${BASE_URL2}api/v1/leads`, {
        method: 'POST',
        body: fdata,
        headers: {
          Authorization: `Bearer ${Token2}`,
        }
      }).then(response => {


        return response.json()
      }).then(data => {

        Swal.fire({
          title: "Thank You!",
          text: "Your submission has been sent.",
          icon: "success",
          confirmButtonText: "Go Back",  // Change the text of the button
          confirmButtonColor: "#000000",  // Set the color of the button (black in this case)
          customClass: {
            confirmButton: 'custom-confirm-button'  // Add a custom class for further styling
          }
        });

        // alert(data.message)
        setCellPhone('')
        setCity('')
        setDealer('')
        setLname('')
        setFname('')
        setEmail('')




      })
    }




    catch (error) {
      console.log(error)

    }



  }




  return (
    <>
      <div>
        <Header />
        <Container fluid>

          <div className='box-image-toyotaspec'>
            <img src={Home} />

          </div>
          <div className='box-image-toyotaspec-mobile'>
            <img src={mabileBanner} />
          </div>
        </Container >
        <Container >

          <div className='box-2-toyota text-center'>
            <h3>Promo Toyota Spektakuler :
              Beli Mobil Toyota Banyak Untungnya</h3>

            <h5>Hi, Toyota Friends!</h5>

            <p>Sekarang kamu bisa menikmati berbagai macam Promo Toyota Spektakuler, di mana kamu bisa membeli
              Avanza, Veloz, dan Rush dengan beragam paket spesial.</p>

            <p>Apa saja sih Promo Spektakuler yang sudah kami siapkan?</p>




            <div className='box-blue-image mb-5 mt-5'>
              <img src={logoblue} />
            </div>

            <p className='paragra-1 mb-5'>Kamu juga berkesempatan untuk mendapatkan gratis asuransi 2 tahun senilai Rp 16 juta,
              Berlaku untuk semua tipe Avanza, Veloz, dan Rush, dengan DP mulai 10%, 15%, 20%, dan 25%.</p>



          </div>

          <div>


            <div className='box-blue-image mb-5 mt-5'>
              <img src={logoReacrt} />
            </div>

          </div>

          <div className='paragra-4 mt-5 mb-5'>
            <p>Tersedia DP mulai dari 10% atau 30 jutaan dan cicilan mulai dari 4 jutaan.</p>
          </div>

          <div className='toss-bac'>
            <img src={Toss} />

          </div>

          <div className='paragra-2 mt-5 mb-5'>
            <p>Sebagai bonus tambahan, tersedia cashback RP 1 juta yang berlaku untuk
              pembelian semua unit tipe Avanza, Rush, Agya dan Calya melalui TOSS</p>
          </div>

          <div className='paragra-3'>
            <p>Yuk, beli mobil Toyota baru sekarang!
              Segera kunjungi dealer Toyota terdekat di kotamu untuk cari tahu
              informasi syarat dan ketentuannya ya!</p>
          </div>

          <div className='toyota-ul'>
            <ul>
              <li>PAKET GRATIS ASURANSI 2 TAHUN BERLAKU UNTUK SEMUA TIPE AVANZA, VELOZ, & RUSH</li>
              <li>PAKET GRATIS ASURANSI 2 TAHUN TERSEDIA DP 10%, 15%, 20%, DAN 25%</li>
              <li>TRANSAKSI ONLINE TOSS BERLAKU DI SELURUH INDONESIA UNTUK DEALER YANG SUDAH BERPARTISIPASI (SELAMA KUOTA MASIH ADA)</li>
              <li>PROGRAM TOSS HANYA BERLAKU UNTUK PEMBELIAN SEMUA UNIT TIPE UNIT AVANZA, VELOZ, RUSH, AGYA, & CALYA (SYARAT DAN KETENTUAN BERLAKU)</li>
              <li>SIMULASI HARGA MENGGUNAKAN OTR DKI JAKARTA, AREA LAIN MENYESUAIKAN</li>
            </ul>
          </div>


          <div className='specta-box'>
            <div className='paragra-5'>
              <p>Jika Anda ingin dihubungi oleh dealer terdekat di kota Anda, mohon isi data berikut</p>
            </div>


            <div className='paragra-6'>
              <h6>Informasi Data Pribadi</h6>
            </div>


            <div>
              <Row className='form-Row'>
                <Col lg={6} md={6} sm={6}>
                  <form>
                    <div className='form-1'>
                      <div>
                        <label>Nama Depan</label>
                      </div>
                      <input type="text"
                        className='input-text'
                        placeholder='Nama Depan'
                        value={Fname}
                        onChange={(e) => setFname(e.target.value)}
                      />
                    </div>

                    <div className='form-1'>
                      <div>
                        <label>No. Handphone</label>
                      </div>
                      <input type="number" className='input-text' placeholder='No. Handphone'
                        value={cellphone}
                        onChange={(e) => setCellPhone(e.target.value)}
                      />
                    </div>

                    <div className='form-1'>
                      <div>
                        <label>Kota</label>
                      </div>


                      <Form.Select aria-label="Default select example" onChange={handleCityChange} value={city}>
                        <option>Kota</option>
                        {Cities &&
                          Cities.map((item, index) => (
                            <option key={index} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                      </Form.Select>
                    </div>


                    {/* <div className='form-1'>
                      <div>
                        <label>Source</labeasxal>
                      </div>
                      <input type="text"
                        className='input-text'
                        placeholder='Source'
                        value={Source}
                        onChange={(e) => setSource(e.target.value)}
                      />
                    </div> */}


                  </form>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <form>
                    <div className='form-1'>
                      <div>
                        <label>Nama Belakang</label>
                      </div>
                      <input type="text" className='input-text' placeholder='Nama Belakang'
                        value={Lname}
                        onChange={(e) => setLname(e.target.value)}
                      />
                    </div>

                    <div className='form-1'>
                      <div>
                        <label>Alamat Email</label>
                      </div>
                      <input type="text" className='input-text' placeholder='Alamat Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                    </div>


                    <div className='form-1'>
                      <div>
                        <label>Dealer</label>
                      </div>

                      <Form.Select aria-label="Default select example" onChange={handleSelectChange} value={dealer}>
                        <option>Dealer</option>
                        {alldealer &&
                          alldealer.map((item, index) => (
                            <option key={index} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                      </Form.Select>
                    </div>


                  </form>
                </Col>
              </Row>
              <div>
                <button className='button-submit' onClick={handleSubmitToyota}>SUBMIT</button>
              </div>
            </div>


          </div>

          <div className='kinto-last-box'>
            <div className='kinto-name-spec'>
              <h4>
                Promo Lainnya
              </h4>
            </div>

            <div className="bottomMainDiv-main-toyota-specta">

              <div className="bottomMainDiv1">
                <div className="bottomDiv2">
                  <img
                    style={{ width: "100%", height: "22rem" }}
                    src={KintoSmall}
                  />
                </div>
                {/* <div className="bottomDiv1">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <div className="card-title-main">
                      Toyota Spektakuler
                    </div>
                    <div className="card-para-main">
                      Beli Avanza, Agya, Veloz, atau Rush dan dapatkan gratis paket
                      suku cadang Rp 2.6 Juta, asuransi 2 tahun Rp 16 Juta, dan
                      paket T-Care. Cek Promo Toyota Spektakuler sekarang!
                    </div>
                    <div>
                      <Link to='/toyotaspec'>
                        <button className="box-2-button">READ MORE</button>
                      </Link>
                    </div>
                  </div>
                </div> */}
                <div className="bottomDiv1">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <div className="card-title-main">
                      KINTO
                    </div>
                    <div className="card-para-main">
                      Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                      untukmu - no DP, bebas pajak progresif, biaya servis, parts &
                      asuransi.
                    </div>
                    <div>
                      <Link to='/kinto'>
                        <button className="box-2-button">READ MORE</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottomMainDiv1">
                <div className="bottomDiv2">
                  <img
                    style={{ width: "100%", height: "22rem" }}
                    src={EZDealSmall}
                  />
                </div>
                <div className="bottomDiv1">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <div className="card-title-main">
                      EZ Deal
                    </div>
                    <div className="card-para-main">
                      Program finansial dari Toyota yang Bikin kamu nggak perlu
                      nunggu lagi untuk punya mobil keren dan jadi makin kece!
                    </div>
                    <div>
                      <Link to='/ezdeal'>
                        <button className="box-2-button">READ MORE</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <Row>
              <Col lg={6} sm={6} md={6}>
                <Card style={{ width: '100%', border: 'none' }}>
                  <Card.Img variant="top" src={ToyotaSpektakulerSmall} />
                  <Card.Body>
                    <Card.Title style={{ textAlign: 'left', }}>
                      <h4 style={{ fontWeight: 700 }}>EZ Deal</h4></Card.Title>
                    <Card.Text style={{ textAlign: 'left' }}>
                      Program finansial dari Toyota yang Bikin kamu nggak perlu nunggu lagi untuk punya mobil keren dan jadi makin kece!
                    </Card.Text>
                    <Card.Text>
                      <Link to='/ezdeal'>
                        <span className='button-roe-card' >READ MORE</span>
                      </Link>
                    </Card.Text>

                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} sm={6} md={6}>
                <Card style={{ width: '100%', border: 'none' }}>
                  <Card.Img variant="top" src={KintoSmall} />
                  <Card.Body>
                    <Card.Title style={{ textAlign: 'left' }}>

                      <h4 style={{ fontWeight: 700 }}>KINTO</h4>
                    </Card.Title>
                    <Card.Text style={{ textAlign: 'left' }}>
                      Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya untukmu - no DP, bebas pajak progresif, biaya servis, parts & asuransi.
                    </Card.Text>
                    <Card.Text>
                      <Link to='/kinto'>
                        <span className='button-roe-card' >READ MORE</span>
                      </Link>
                    </Card.Text>

                  </Card.Body>
                </Card>
              </Col>
            </Row> */}
          </div>





        </Container>


        <div className="bottom-button">
          <hr />
          <button onClick={scrollToTop}>Back to top​ <FontAwesomeIcon icon={faAngleUp} /> </button>
        </div>
        <hr />


        <Footer />

      </div>
    </>
  )
}