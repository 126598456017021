import React from "react";

export default function LinearSearch() {
    const arr = [1, 4, 2, 6, 34, 222]
    const target = 34

    const handleLinearSeach = () => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === target) {
                console.log("sacjksebj", i)
                return i

            }


        }
        return -1
    }


    return (
        <>

            <button onClick={handleLinearSeach}>found</button>
        </>
    )
}