import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, Button, Offcanvas, NavDropdown, Form, Card, Row, Col, Carousel, Accordion } from 'react-bootstrap'
import './homePageStyle.css'
import logo1 from '../../../assets/image/homePage.png'
import faq1 from '../../../assets/image/faq1.png'
import faq2 from '../../../assets/image/faq2.png'
import Promo from '../../../assets/image/Promo.png'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Mpv from "../../../component/car_tabs/MPV";
import Footer from "../../footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import HeaderTrans from "../../header";

import MobileTabs from "./MobileTabs";



import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import { BASE_URL, Token } from "../../../Config";
import axios from "axios";
import { Link } from "react-router-dom";








export default function HomePage() {
    const [key, setKey] = useState('1');
    const [show, setShow] = React.useState(1);
    const [productCate, setProductCate] = useState([])
    const [productList, setProductList] = useState([])


    const data = ['fgccfg', 'ghvgvc', 'gvg', 'jjj', 'kkk', 'lll'];

    const [showButton, setShowButton] = useState(false);



    useEffect(() => {

        const handleScroll = () => {
            setShowButton(window.scrollY > 300);
        };


        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };



    }, []);

    const scrollToTop = () => {
        // Smoothly scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {

        handleProductCategory()


        handleFecthData()
    }, [Token])


    const handleFecthData = async () => {
        try {


            const response = await fetch(`${BASE_URL}/api/frontend/products?category=1`, {
                headers: {

                    Authorization: `Bearer ${Token}`,


                }
            });
            const data = await response.json();
            console.log("published-product", data);
            setProductList(data)






        } catch (error) {
            console.error('Error fetching buyer data:', error);
        }
    }





    const handleProductCategory = async () => {
        try {


            const response = await fetch(`https://finance-toyota.thriveagency.id/api/frontend/product-categories`, {
                headers: {

                    Authorization: `Bearer ${Token}`,


                }
            });
            const data = await response.json();
            console.log("published/profile", data);
            setProductCate(data)





        } catch (error) {
            console.error('Error fetching buyer data:', error);
        }

    }


    const handleProductList = async (k) => {
        setKey(k)
        try {


            const response = await fetch(`${BASE_URL}/api/frontend/products?category=${k}`, {
                headers: {

                    Authorization: `Bearer ${Token}`,


                }
            });
            const data = await response.json();
            console.log("published-product", data);
            setProductList(data)






        } catch (error) {
            console.error('Error fetching buyer data:', error);
        }

    }



    // const handleProductList = () => {
    //     axios.get('http://finance-toyota.thriveagency.id/api/frontend/product-categories', {
    //         headers: {
    //             Authorization: 'Bearer $2y$10$p3TUIDGmEgpbv3AVYywU5.fX8hDr2OcK1.xBOgumInmjn6UiWI0yi',
    //         }
    //     }).then(response => {
    //         console.log("res==>", response)
    //     }).catch(error => console.log("err==>", error))
    // }





    return (
        <>
            <HeaderTrans />


            <div className="main-box">

                <Container>
                    {/* <Row>
                        <Col lg={6} sm={12} md={12} className="order-lg-1 order-2">
                          

                            <Swiper
                                slidesPerView={1}
                                spaceBetween={30}
                                // loop={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className="box-1">
                                        <h1>KINTO</h1>
                                        <div className="sub-box-2">
                                            <h6 style={{ textAlign: 'left' }}>Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                                                untukmu - no DP, bebas pajak progresif, biaya servis, parts &
                                                asuransi.</h6>

                                            <button className="box-1-button" style={{cursor:'pointer'}}>READ MORE</button>

                                        </div>


                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="box-1">
                                        <h1>KINTO</h1>
                                        <div className="sub-box-2">
                                            <h6 style={{ textAlign: 'left' }}>Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                                                untukmu - no DP, bebas pajak progresif, biaya servis, parts &
                                                asuransi.</h6>

                                            <button className="box-1-button" style={{cursor:'pointer'}}>READ MORE</button>

                                        </div>


                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="box-1">
                                        <h1>KINTO</h1>
                                        <div className="sub-box-2">
                                            <h6 style={{ textAlign: 'left' }}>Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                                                untukmu - no DP, bebas pajak progresif, biaya servis, parts &
                                                asuransi.</h6>

                                            <button className="box-1-button" style={{cursor:'pointer'}}>READ MORE</button>

                                        </div>


                                    </div>
                                </SwiperSlide>

                            </Swiper>








                        </Col>
                        <Col lg={6} sm={12} md={12} className="order-lg-2 order-1">
                            <div className="image-design">
                                <img src={logo1} />
                              

                            </div>
                        </Col>
                    </Row> */}


                    {/* <div className="mainDiv">
                        <div className="secondDiv">
                            <div className="secondDiv1">

                                {show === 1 && (
                              
                                    <div className="box-1">
                                        <h1>KINTO</h1>
                                        <div className="sub-box-2">
                                            <h6 style={{ textAlign: 'left' }}>Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                                                untukmu - no DP, bebas pajak progresif, biaya servis, parts &
                                                asuransi. </h6>

                                            <button className="box-1-button" style={{cursor:'pointer'}}>READ MORE</button>

                                        </div>


                                    </div>
                                )}
                                {show === 2 && (
                                    <div className="box-1">
                                        <h1>KINTO</h1>
                                        <div className="sub-box-2">
                                            <h6 style={{ textAlign: 'left' }}>Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                                                untukmu - no DP, bebas pajak progresif, biaya servis, parts &
                                                asuransi. Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                                                untukmu - no DP, bebas pajak progresif, biaya servis, parts &
                                                asuransi</h6>

                                            <button className="box-1-button" style={{cursor:'pointer'}}>READ MORE</button>

                                        </div>


                                    </div>
                                )}
                                {show === 3 && (
                                    <div className="box-1">
                                        <h1>KINTO</h1>
                                        <div className="sub-box-2">
                                            <h6 style={{ textAlign: 'left' }}>Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                                                untukmu - no DP, bebas pajak progresif, biaya servis, parts &
                                                asuransi. Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                                                untukmu - no DP, bebas pajak progresif, biaya servis, parts &
                                                asuransi. Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                                                untukmu.</h6>

                                            <button className="box-1-button" style={{cursor:'pointer'}}>READ MORE</button>

                                        </div>


                                    </div>
                                )}

                                <div className="sliderMain">
                                    <div
                                        onClick={() => setShow(show === 1 ? 1 : show - 1)}
                                        className={show === 1 ? "sliderCircleDull" : "sliderCircle"}
                                    >
                                        <FontAwesomeIcon icon={faAngleLeft} />
                                    </div>
                                    <div className="LineDiv">
                                        <div
                                            onClick={() => setShow(1)}
                                            className={show === 1 ? "orangeLine" : "grayDot"}
                                        ></div>
                                        <div
                                            onClick={() => setShow(2)}
                                            className={show === 2 ? "orangeLine" : "grayDot"}
                                        ></div>
                                        <div
                                            onClick={() => setShow(3)}
                                            className={show === 3 ? "orangeLine" : "grayDot"}
                                        ></div>

                                    </div>
                                    <div
                                        onClick={() => setShow(show === 3 ? 3 : show + 1)}
                                        className={show === 3 ? "sliderCircleDull" : "sliderCircle"}                                    >
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </div>
                                </div>
                            </div>
                            <div className="secondDiv2">
                                <img className="image1" src={logo1} />
                            </div>
                        </div>
                    </div> */}

                </Container>

                <Container fluid>
                    {/* <img src={Promo} className="image-landing" />


                    <div className="arrow-slider1">
                        <ul>
                            <li>sax</li>
                            <li>sax2</li>
                            <li>sax3</li>
                        </ul>
                    </div>


                    <div className="arrow-slider2">
                        <ul>
                            <li><FontAwesomeIcon icon={faAngleRight} /></li>
                            <li><FontAwesomeIcon icon={faAngleLeft} /></li>

                        </ul>
                    </div> */}

                    {/* <div className="pos-banner">


                        <div className="display-banner-slider">
                            <div>
                                <ul>
                                    <li>sax</li>
                                    <li>sax2</li>
                                    <li>sax3</li>
                                </ul>
                            </div>
                            <div className="arrow-slider">
                                <ul>
                                    <li><FontAwesomeIcon icon={faAngleRight} /></li>
                                    <li><FontAwesomeIcon icon={faAngleLeft} /></li>

                                </ul>
                            </div>
                        </div>
                    </div> */}
                </Container>

            </div>

            <div className="web-display">

                <div className="mt-5 mb-5">

                    <h2 className="text-center temukam" style={{ fontWeight: '700', fontSize: '35px' }} >Temukan Mobil Toyota Impianmu </h2>
                    <div className="overflow-x-auto mt-5">
                        <Container>

                            {/* <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3"
                                style={{ width: '100%' }}
                            >

                                <Tab eventKey="1" title="SUV">

                                    <Mpv keyss={key} />
                                </Tab>
                                <Tab eventKey="2" title="Hatchback" >

                                    <Mpv keyss={key} />
                                </Tab>
                                <Tab eventKey="3" title="Hybrid">

                                    <Mpv keyss={key} />
                                </Tab>
                                <Tab eventKey="4" title="MPV">

                                    <Mpv keyss={key} />
                                </Tab>
                                <Tab eventKey="5" title="Sedan">

                                    <Mpv />
                                </Tab>
                                <Tab eventKey="6" title="Commercial">

                                    <Mpv keyss={key} />
                                </Tab>
                                <Tab eventKey="7" title="Sport">

                                    <Mpv keyss={key} />
                                </Tab>

                            </Tabs> */}




                            {productCate.length > 0 ? (
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    // onSelect={(k) => setKey(k)}
                                    onSelect={(k) => handleProductList(k)}
                                    className="mb-3 tabb"
                                    style={{ width: '100%' }}
                                >
                                    {productCate?.map((item, index) => {
                                        console.log("'dcdsc", item, index, key);

                                        return (
                                            <Tab key={item.id} eventKey={item.id.toString()} title={item.name} className="tabs-lijds">
                                                <Mpv data={productList} />
                                            </Tab>
                                        );
                                    })}
                                </Tabs>
                            ) : (
                                "No Data Found"
                            )}



                        </Container>
                    </div>
                </div>
            </div >

            <div className="mobile-display">
                <h2 className="text-center mobile-text" >Temukan Mobil Toyota Impianmu </h2>

                <MobileTabs />
            </div>



            {/* <div className="text-center mt-5 mb-5">
                <button className="button-FAQ"> Get your Offer</button>

            </div>

            <div className="text-center mt-5 mb-5 FAQ">
                <h2>Frequently Asked Questions</h2>
            </div> */}


            <div className="box-3">
                <Container>

                    <div className="sub-box-3">
                        <Row>
                            <Col lg={6} sm={12} md={6}>

                                <Card style={{ width: '100%', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Card.Img variant="top" src={faq1} style={{ width: '60%', height: '60%', position: 'relative', top: '-26px' }} />
                                </Card>
                            </Col>
                            <Col lg={6} sm={12} md={6}>
                                <div>
                                    <div className="Kinto-category-box">
                                        {/* <h6>YANG BISA KAMU LAKUKAN DEGAN JAGO</h6> */}
                                        <h2 className="text-font">KINTO</h2>
                                        <p>Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya untukmu - no DP, bebas pajak progresif, biaya servis, parts & asuransi.</p>
                                        <Link to="/kinto" className="link-tyext"> <button className="bu" style={{ cursor: 'pointer' }}>READ MORE</button></Link>

                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Benefit 1</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Benefit 2</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Benefit 3</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Benefit 4</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Benefit 5</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>


                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>

                </Container>


            </div>


            <div className="box-3 mt-5">
                <Container>
                    <div className="sub-box-3">
                        <Row>

                            <Col lg={6} sm={12} md={6} className="order-lg-1 order-2">
                                {/* <div>
                                    <div className="Kinto-category-box">
                                        <h6>EKOSISTEM & PARTNER</h6>
                                        <p>Jago berkolaborasi dengan ekosistem digital Indonesia, sehingga investasi transaksi semakin mudah</p>
                                        <button className="button-acc">Selengkapnya</button>

                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Gopay</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px' }} className="accordian-text"> Nikmati keunggulan Jago & kemudahan GoPay dalam stau fitur. Transaksi praktsi bunga 2.5% p.a*. tanpa batas saldo. </p>
                                                    <button className="button-acc">Cek Gopay Tabungan by Jago</button>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Seperti punya cabang bank pribadi</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px' }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>
                                                   
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Tansaksi praktis dengan QRIS Jago</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px' }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>
                                                   
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Kartu debit jago Visa diteriman di seluruh dunia </Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px' }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>
                                                   
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Bunga kompetitif dan bebas penalti di deposito jago </Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px' }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>
                                                   
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div> */}

                                <div>
                                    <div className="Kinto-category-box">
                                        {/* <h6>YANG BISA KAMU LAKUKAN DEGAN JAGO</h6> */}
                                        <h2 className="text-font">TOYOTA SPEKTAKULER</h2>
                                        <p>Beli Avanza, Agya, Veloz, atau Rush dan dapatkan gratis paket suku cadang Rp 2.6 Juta, asuransi 2 tahun Rp 16 Juta, dan paket T-Care. Cek Promo Toyota Spektakuler sekarang!</p>
                                        <Link to="/toyotaspec" className="link-tyext">  <button className="bu" style={{ cursor: 'pointer' }}> READ MORE</button></Link>

                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Benefit 1</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Benefit 2</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Benefit 3</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Benefit 4</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Benefit 5</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>


                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} sm={12} md={6} className="order-lg-1 order-1">
                                {/* <Card style={{ width: '100%', border: 'none' }}>
                                    <Card.Img variant="top" src={faq2} style={{ height: '35rem' }} />


                                </Card> */}
                                <Card style={{ width: '100%', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Card.Img variant="top" src={faq2} style={{ width: '50%', height: '50%' }} />
                                </Card>
                            </Col>
                        </Row>

                    </div>

                </Container>


            </div>

            <div className="box-3 mt-5">
                <Container>
                    <div className="sub-box-3">
                        <Row>
                            <Col lg={6} sm={12} md={6}>

                                <Card style={{ width: '100%', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Card.Img variant="top" src={faq1} style={{ width: '60%', height: '60%', position: 'relative', top: '-26px' }} />
                                </Card>
                            </Col>
                            <Col lg={6} sm={12} md={6}>
                                {/* <div>
                                    <div className="Kinto-category-box">
                                        <h6>YANG BISA KAMU LAKUKAN DEGAN JAGO</h6>
                                        <p>Nabung, transaksi, dan atur uang semakin mudah & aman, langsung dari aplikasi</p>
                                      

                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>40 Katntong Jago = 40 rekening</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px' }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>
                                                   
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Seperti punya cabang bank pribadi</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px' }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>
                                                   
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Tansaksi praktis dengan QRIS Jago</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px' }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>
                                                   
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Kartu debit jago Visa diteriman di seluruh dunia </Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px' }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>
                                                   
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Bunga kompetitif dan bebas penalti di deposito jago </Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px' }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>
                                                   
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div> */}
                                <div>
                                    <div className="Kinto-category-box">
                                        {/* <h6>YANG BISA KAMU LAKUKAN DEGAN JAGO</h6> */}
                                        <h2 className="text-font">EZ DEAL</h2>
                                        <p>Program finansial dari Toyota yang Bikin kamu nggak perlu nunggu  lagi untuk punya mobil keren dan jadi makin kece!</p>
                                        <Link to="/ezdeal" className="link-tyext">     <button className="bu" style={{ cursor: 'pointer' }}>  READ MORE </button></Link>

                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Benefit 1</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Benefit 2</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Benefit 3</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Benefit 4</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Benefit 5</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ textAlign: 'left', color: 'grey', fontSize: '17px', fontWeight: 400 }} className="accordian-text"> Bisa buat 40 rekening untuk memisahkan berbagai kebutuhan keuangan di satu aplikasi, tanpa admin bulanan.</p>

                                                </Accordion.Body>
                                            </Accordion.Item>


                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>

                </Container>


            </div>


            <div className="bottom-button">
                <hr className="hhhrrr" />
                <button onClick={scrollToTop}>Back to top​ <FontAwesomeIcon icon={faAngleUp} /> </button>
            </div>
            <hr />




            <Footer />




            {/* <div className="mobile-scroll-container">
                <div className="mobile-scroll-content">
                    {data.map((item, index) => (
                        <div key={index} className="list-item">
                            {item}
                        </div>
                    ))}
                </div>
            </div> */}
        </>
    )
}