import React, { useEffect, useState } from "react";
import "./KintoPageStyle.css";
import ZEDealImage from "./ZEDealImage.png";
import Kinto1 from "./Kinto1.png";
import KintoMobile from '../../../assets/image/kinto-mobile-banner.png'
import stepsubscribekinto1 from "./stepsubscribekinto1.png";
import stepsubscribekinto2 from "./stepsubscribekinto2.png";
import stepsubscribekinto3 from "./stepsubscribekinto3.png";
import stepsubscribekinto4 from "./stepsubscribekinto4.png";
import GooglePlay from "./GooglePlay.png";
import AppStore from "./AppStore.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";

import {
  Navbar,
  Container,
  Nav,
  Button,
  Offcanvas,
  NavDropdown,
  Form,
  Card,
  Row,
  Col,
  Carousel,
  Accordion,
} from "react-bootstrap";
import Footer from "../../footer";
import Header from "../../header/Header";
import { BASE_URL, Token, BASE_URL2, Token2 } from "../../../Config";
import KintoSmall from "../../../assets/image/KintoSmall.png";
import ToyotaSpektakulerSmall from "../../../assets/image/ToyotaSpektakulerSmall.png";
import EZDealSmall from "../../../assets/image/EZDealSmall.png";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import KintoMobile1 from '../../../assets/image/1.png'
import KintoMobile2 from '../../../assets/image/2.png'
import KintoMobile3 from '../../../assets/image/3.png'
import KintoMobile4 from '../../../assets/image/4.png'
import KintoMobile5 from '../../../assets/image/5.png'
import KintoMobile6 from '../../../assets/image/6.png'
import KintoMobile7 from '../../../assets/image/7.png'
import KintoMobile8 from '../../../assets/image/8.png'

const Kinto = () => {
  const [open, setOpen] = useState(null);
  const scrollToTop = () => {
    // Smoothly scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [show, setShow] = React.useState(1);

  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [cellphone, setCellPhone] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [dealer, setDealer] = useState("");
  const [model, setModel] = useState([]);
  const [product, setProduct] = useState("");
  const [Kinto, setKinto] = useState("");
  const [duration, setDuration] = useState("");

  const [Cities, setCities] = useState([]);
  const [alldealer, setAllDealer] = useState([]);
  const [allproduct, setAllPRoduct] = useState([]);

  const [productCate, setProductCate] = useState([]);









  const CitiesStatic = [
    {
      value: "Jakarta Utara",
      id: 67
    },
    {
      value: "Jakarta Selatan",
      id: 70
    },
    {
      value: "Jakarta Timur",
      id: 69
    },
    {
      value: "Jakarta Barat",
      id: 71
    },
    {
      value: "Jakarta Pusat",
      id: 68
    },
    {
      value: "Bogor",
      id: 47
    },
    {
      value: "Depok",
      id: 46
    },
    {
      value: "Tangerang",
      id: 3
    },

    {
      value: "Tangerang Selatan",
      id: 187
    },
    {
      value: "Bekasi",
      id: 6
    },
    {
      value: "Bandung",
      id: 51
    },
    {
      value: "Semarang",
      id: 56
    },
    {
      value: "Surabaya",
      id: 1
    },
    {
      value: "Denpasar",
      id: 106
    },
  ]

  const ProductKinto = [
    {
      value: "KINTO One New",
      id: 1
    },
    {
      value: "KINTO One Ekonomis",
      id: 2
    },

  ]


  const ModalKinto = [
    {
      value: "Toyota Agya",
      id: 29
    },
    {
      value: "Toyota Calya",
      id: 52
    },
    {
      value: "Toyota Avanza",
      id: 44
    },
    {
      value: "Toyota Veloz",
      id: 45
    },
    {
      value: "Toyota Raize",
      id: 55
    },
    {
      value: "Toyota Rush",
      id: 35
    },
    {
      value: "Toyota Yaris",
      id: 36
    },
    {
      value: "Toyota Yaris Cross",
      id: 62
    },

    {
      value: "Toyota Yaris Cross HEV",
      id: 63
    },
    {
      value: "Toyota Kijang Innova",
      id: 33
    },
    {
      value: "Toyota Vios",
      id: 39
    },
    {
      value: "Toyota Kijang Innova Zenix",
      id: 58
    },
    {
      value: "Toyota Kijang Innova Zenix HEV",
      id: 59
    },
    {
      value: "Toyota Toyota Kijang Innova Reborn",
      id: 33
    },
    {
      value: "Toyota Voxy",
      id: 30
    },
    {
      value: "Toyota Fortuner",
      id: 31
    },
    {
      value: "Toyota Corolla Cross HEV",
      id: 54
    },
    {
      value: "Toyota Corolla Altis",
      id: 51
    },
    {
      value: "Toyota Corolla Altis HEV",
      id: 50
    },
    {
      value: "Toyota Alphard",
      id: 37
    },
    {
      value: "Toyota Alphard HEV",
      id: 21
    },
    {
      value: "Toyota Camry",
      id: 41
    },
    {
      value: "Toyota Camry HEV",
      id: 42
    },
    {
      value: "Toyota Bz4X",
      id: 57
    },
    {
      value: "Toyota LC300",
      id: 24
    },
    {
      value: "Toyota Hilux Single Cabin",
      id: 61
    },
    {
      value: "Toyota Hilux Double Cabin",
      id: 17
    },
    {
      value: "Toyota Hiace",
      id: 32
    },
  ]


  const Duration = [
    { value: "12 Bulan" },
    { value: "24 Bulan" },
    { value: "36 Bulan " },
    { value: "48 Bulan" },
    { value: "60 Bulan" }

  ]

  const handleCityChange = (e) => {
    setCity(e.target.value);
    handleDealers(e.target.value);
  };

  const handleDurartionChange = (e) => {
    setDuration(e.target.value);

  }

  const handleModelChange = (e) => {
    setModel(e.target.value);
    handleProduct(e.target.value);
  };

  const handleProductChange = (e) => {
    setProduct(e.target.value);
  };

  const handleDealers = async (CITY) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/frontend/dealers?city=${CITY}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      const data = await response.json();
      console.log("published/daelers", data);

      setAllDealer(data);
    } catch (error) {
      console.error("Error fetching buyer data:", error);
    }
  };

  const handleProduct = async (CITY) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/frontend/products?category=${CITY}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      const data = await response.json();
      console.log("published/daelers", data);

      setAllPRoduct(data);
    } catch (error) {
      console.error("Error fetching buyer data:", error);
    }
  };

  useEffect(() => {
    handleProductCategory();
    handleCities();
  }, []);

  const handleCities = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/frontend/cities`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      const data = await response.json();
      console.log("published/Cities", data);
      setCities(data);
    } catch (error) {
      console.error("Error fetching buyer data:", error);
    }
  };

  const handleProductCategory = async () => {
    try {
      const response = await fetch(
        `https://finance-toyota.thriveagency.id/api/frontend/product-categories`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      const data = await response.json();
      console.log("published/profile", data);
      setProductCate(data);
    } catch (error) {
      console.error("Error fetching buyer data:", error);
    }
  };

  const handleSubmitKinto = (e) => {
    e.preventDefault();
    console.log(
      "formdara",
      Lname,
      Fname,
      city,
      cellphone,
      model,
      email,
      duration,
      Kinto,
      product
    );

    try {
      let fdata = new FormData();

      fdata.append("name", `${Fname} ${Lname}`);
      fdata.append("email", email);
      fdata.append("phone", cellphone);
      fdata.append("city_id", city);
      fdata.append("product_id", model);
      fdata.append("question", `${product} ${duration}`);
      fdata.append("source", "Website-Kinto");

      fetch(`${BASE_URL2}api/v1/leads`, {
        method: "POST",
        body: fdata,
        headers: {
          Authorization: `Bearer ${Token2}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {


          Swal.fire({
            title: "Thank You!",
            text: "Your submission has been sent.",
            icon: "success",
            confirmButtonText: "Go Back",  // Change the text of the button
            confirmButtonColor: "#000000",  // Set the color of the button (black in this case)
            customClass: {
              confirmButton: 'custom-confirm-button'  // Add a custom class for further styling
            }
          });


          // alert(data.message);
          setCellPhone("");
          setCity("");
          setDealer("");
          setLname("");
          setFname("");
          setEmail("");
          setModel("");
          setProduct("");
          setDuration("");
          setKinto("");
        });
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <div className="mainDiv">
        <div className="secondDiv">
          <div className="secondDiv1">
            <div className="KintoText">KINTO</div>
            {show === 1 && (
              <div className="KintoDescription">
                <div style={{ marginBottom: "20px" }}>
                  Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                  untukmu - no DP, bebas pajak progresif, biaya servis, parts &
                  asuransi.
                </div>
                <div>
                  <button className="box-2-button">READ MORE</button>
                </div>
              </div>
            )}
            {show === 2 && (
              <div className="KintoDescription">
                <div style={{ marginBottom: "20px" }}>
                  Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                </div>
                <div>
                  <button className="box-2-button">READ MORE</button>
                </div>
              </div>
            )}
            {show === 3 && (
              <div className="KintoDescription">
                <div style={{ marginBottom: "20px" }}>
                  Berkendara bebas ribet pakai KINTO. Unit dedikasi hanya
                  untukmu - no DP, bebas pajak progresif, biaya servis, parts &
                  asuransi. Berkendara bebas ribet pakai KINTO. Unit dedikasi
                  hanya untukmu - no DP, bebas pajak progresif, biaya servis,
                  parts & asuransi.
                </div>
                <div>
                  <button className="box-2-button">READ MORE</button>
                </div>
              </div>
            )}

            <div className="sliderMain">
              <div
                onClick={() => setShow(show === 1 ? 1 : show - 1)}
                className="sliderCircle"
              >
                <IoIosArrowBack />
              </div>
              <div className="LineDiv">
                <div
                  onClick={() => setShow(1)}
                  className={show === 1 ? "orangeLine" : "grayDot"}
                ></div>
                <div
                  onClick={() => setShow(2)}
                  className={show === 2 ? "orangeLine" : "grayDot"}
                ></div>
                <div
                  onClick={() => setShow(3)}
                  className={show === 3 ? "orangeLine" : "grayDot"}
                ></div>
               
              </div>
              <div
                onClick={() => setShow(show === 3 ? 3 : show + 1)}
                className="sliderCircle"
              >
                <IoIosArrowForward />
              </div>
            </div>
          </div>
          <div className="secondDiv2">
            <img className="image1" src={KintoNew} />
          </div>
        </div>
      </div> */}

      <Header />
      <div
        style={{
          width: "100%",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="KintoImageDiv">
          <img style={{ width: "100%" }} src={Kinto1} />
        </div>


        <div className="KintoImageDiv-mobile">
          <img style={{ width: "100%" }} src={KintoMobile} />
        </div>

        <div className="KintoTextDiv1">
          Solusi baru berkendara yang aman dan tanpa ribet
        </div>
        <div className="KintoTextDiv2">Pakai mobil, gak pakai ribetnya!</div>
        <div className="KintoTextDiv3">
          #MobilitasTanpaBeban #NyetirLebihAman
        </div>
        <div className="Kintobutton">
          <button className="box-2-button-2" style={{}}>
            SUBSCRIBE NOW
          </button>
        </div>
        <div className="KintoTextDiv4">LANGKAH MUDAH BERLANGGANAN KINTO</div>
        <div className="web-display-kinto">



          <div className="kintoImage2">
            <img
              style={{
                width: "70%",
              }}
              src={stepsubscribekinto1}
            />
          </div>
          <div className="kintoImage2">
            <img
              style={{
                width: "70%",
              }}
              src={stepsubscribekinto3}
            />
          </div>
          <div className="kintoImage2">
            <img
              style={{
                width: "70%",
              }}
              src={stepsubscribekinto2}
            />
          </div>
          <div className="kintoImage2">
            <img
              style={{
                width: "70%",
              }}
              src={stepsubscribekinto4}
            />
          </div>

        </div>
        <div className="mobile-display-kinto">
          <Row>
            <Col sm={12} xs={12}>
              <div className="mobile-kinto-image">
                <img
                  style={{
                    width: "90%",
                  }}
                  src={KintoMobile1}
                />
              </div>
            </Col>
            <Col sm={12} xs={12}>
              <div className="mobile-kinto-image">
                <img
                  style={{
                    width: "90%",
                  }}
                  src={KintoMobile2}
                />
              </div>
            </Col>
            <Col sm={12} xs={12}>
              <div className="mobile-kinto-image">
                <img
                  style={{
                    width: "90%",
                  }}
                  src={KintoMobile3}
                />
              </div>
            </Col>
            <Col sm={12} xs={12}>
              <div className="mobile-kinto-image">
                <img
                  style={{
                    width: "90%",
                  }}
                  src={KintoMobile4}
                />
              </div>
            </Col>
            <Col sm={12} xs={12}>
              <div className="mobile-kinto-image">
                <img
                  style={{
                    width: "90%",
                  }}
                  src={KintoMobile5}
                />
              </div>
            </Col>
            <Col sm={12} xs={12}>
              <div className="mobile-kinto-image">
                <img
                  style={{
                    width: "90%",
                  }}
                  src={KintoMobile6}
                />
              </div>
            </Col>
            <Col sm={12} xs={12}>
              <div className="mobile-kinto-image">
                <img
                  style={{
                    width: "90%",
                  }}
                  src={KintoMobile7}
                />
              </div>
            </Col>
            <Col sm={12} xs={12}>
              <div className="mobile-kinto-image">
                <img
                  style={{
                    width: "90%",
                  }}
                  src={KintoMobile8}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="KintoTextDiv5">
          E-Catalog Kinto dapat didownload disini
        </div>

        <div className="GooglePlayDiv">
          <div className="">
            <img
              // style={{
              //   width: "70%",
              // }}
              src={GooglePlay}
            />
          </div>
          <div className="">
            <img
              // style={{
              //   width: "70%",
              // }}
              src={AppStore}
            />
          </div>
        </div>
        <div className="Kinto-box">

          <div className="KintoTextDiv6">
            Jika Anda ingin dihubungi oleh Kinto, mohon isi data berikut
          </div>
          <div className="KintoTextDiv7">Informasi Data Pribadi</div>

          <div className="KintoinputDiv8 text-center">




            <Row>
              <Col lg={6} md={6} sm={6}>
                <form>
                  <div className="form-1">
                    <div>
                      <label>Nama Depan</label>
                    </div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Nama Depan"
                      value={Fname}
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </div>
                </form>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <form>
                  <div className="form-1">
                    <div>
                      <label>Nama Belakang</label>
                    </div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Nama Belakang"
                      value={Lname}
                      onChange={(e) => setLname(e.target.value)}
                    />
                  </div>
                </form>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <div className="form-1">
                  <div>
                    <label>No. Handphone</label>
                  </div>
                  <input
                    type="number"
                    className="input-text"
                    placeholder="No. Handphone"
                    value={cellphone}
                    onChange={(e) => setCellPhone(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <div className="form-1">
                  <div>
                    <label>Alamat Email</label>
                  </div>
                  <input
                    type="text"
                    className="input-text"
                    placeholder="Alamat Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <div className="form-1">
                  <div>
                    <label>Kota</label>
                  </div>

                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleCityChange}
                    value={city}
                  >
                    <option>Kota</option>
                    {CitiesStatic &&
                      CitiesStatic.map((item, index) => (
                        <option key={index} value={item?.id}>
                          {item?.value}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </Col>

              <Col lg={6} md={6} sm={6}>
                {/* <div className="form-1">
                  <div>
                    <label>Durasi berlangganan</label>
                  </div>
                  <input
                    type="text"
                    className="input-text"
                    placeholder="Durasi berlangganan"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                  />
                </div> */}
                <div className="form-1">
                  <div>
                    <label>Produk KINTO</label>
                  </div>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleProductChange}
                    value={product}
                  >
                    <option>Produk KINTO</option>
                    {ProductKinto &&
                      ProductKinto.map((item, index) => (
                        <option key={index} value={item?.value}>
                          {item?.value}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </Col>

              <Col lg={6} md={6} sm={6}>
                <div className="form-1">
                  <div>
                    <label>Model yang diinginkan</label>
                  </div>

                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleModelChange}
                    value={model}
                  >
                    <option>Model yang diinginkan</option>
                    {ModalKinto &&
                      ModalKinto.map((item, index) => (
                        <option key={index} value={item?.id}>
                          {item?.value}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </Col>

              <Col lg={6} md={6} sm={6}>
                <div className="form-1">
                  <div>
                    <label>Durasi berlangganan</label>
                  </div>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleDurartionChange}
                    value={duration}
                  >
                    <option>Durasi berlangganan</option>
                    {Duration &&
                      Duration.map((item, index) => (
                        <option key={index} value={item?.value}>
                          {item?.value}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </Col>

              {/* <Col lg={6} md={6} sm={6}>
                <form>
                  <div className="form-1">
                    <div>
                      <label>Source</label>
                    </div>
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Source"
                      value={Kinto}
                      onChange={(e) => setKinto(e.target.value)}
                    />
                  </div>
                </form>
              </Col> */}
            </Row>
            <button className="button-roe" onClick={handleSubmitKinto}>
              SUBMIT
            </button>

          </div>
        </div>

        <div className="KintoTextDiv8">TERMS & CONDITIONS</div>
        <div className="KintoTextDiv9">
          KINTO merupakan solusi baru berkendara dengan cara berlangganan selama
          36 bulan dengan fasilitas full service
        </div>
        <div className="KintoTextDiv9">
          All In One Package: Biaya berlangganan KINTO sudah dilengkapi dengan
          asuransi kendaraan, servis berkala, dan perpanjangan pajak kendaraan
        </div>
        <div className="KintoTextDiv9">
          One Price: Biaya berlangganan yang sama di seluruh area Jabodetabek
        </div>
        <div className="KintoTextDiv9">
          Online services: Pemesanan KINTO yang dapat dilakukan secara online
        </div>


        <div className="KintoTextDiv11">Promo Lainnya</div>

        <div className="KintoTextDiv10">

          <Row>
            <Col lg={6} md={6} sm={12}>
              <Card style={{ width: '100%', border: 'none' }}>
                <Card.Img variant="top" src={ToyotaSpektakulerSmall} />
                <Card.Body>
                  <div className="card-title-main">
                    Toyota Spektakuler
                  </div>
                  <div className="card-para-main">
                    Beli Avanza, Agya, Veloz, atau Rush dan dapatkan gratis paket
                    suku cadang Rp 2.6 Juta, asuransi 2 tahun Rp 16 Juta, dan
                    paket T-Care. Cek Promo Toyota Spektakuler sekarang!
                  </div>
                  <div>
                    <Link to='/toyotaspec'>
                      <button className="box-2-button">READ MORE</button>
                    </Link>
                  </div>


                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Card style={{ width: '100%', border: 'none' }}>
                <Card.Img variant="top" src={EZDealSmall} />
                <Card.Body>
                  <div className="card-title-main">
                    EZDeal
                  </div>
                  <div className="card-para-main">
                    Program finansial dari Toyota yang Bikin kamu nggak perlu nunggu lagi untuk punya mobil keren dan jadi makin kece!
                  </div>
                  <div>
                    <Link to='/ezdeal'>
                      <button className="box-2-button">READ MORE</button>
                    </Link>
                  </div>


                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>





        {/* 
        <div className="bottomMainDiv-main">
          <div className="bottomMainDiv1">
            <div className="bottomDiv2">
              <img
                style={{ width: "100%", height: "22rem" }}
                src={ToyotaSpektakulerSmall}
              />
            </div>
            <div className="bottomDiv1">
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  padding: "10px 0px 10px 0px",
                }}
              >
                <div className="card-title-main">
                  Toyota Spektakuler
                </div>
                <div className="card-para-main">
                  Beli Avanza, Agya, Veloz, atau Rush dan dapatkan gratis paket
                  suku cadang Rp 2.6 Juta, asuransi 2 tahun Rp 16 Juta, dan
                  paket T-Care. Cek Promo Toyota Spektakuler sekarang!
                </div>
                <div>
                  <Link to='/toyotaspec'>
                    <button className="box-2-button">READ MORE</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="bottomMainDiv1">
            <div className="bottomDiv2">
              <img
                style={{ width: "100%", height: "22rem" }}
                src={EZDealSmall}
              />
            </div>
            <div className="bottomDiv1">
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  padding: "10px 0px 10px 0px",
                }}
              >
                <div className="card-title-main">
                  EZ Deal
                </div>
                <div className="card-para-main">
                  Program finansial dari Toyota yang Bikin kamu nggak perlu
                  nunggu lagi untuk punya mobil keren dan jadi makin kece!
                </div>
                <div>
                  <Link to='/ezdeal'>
                    <button className="box-2-button">READ MORE</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <Container>

          {/* <div className='kinto-last-box'>
            <div className='kinto-name-kinto'>
              <h4>
                Promo Lainnya
              </h4>
            </div>

            <div className="bottomMainDiv-main-toyota-kinto">

              <div className="bottomMainDiv1">
                <div className="bottomDiv2">
                  <img
                    style={{ width: "100%", height: "22rem" }}
                    src={ToyotaSpektakulerSmall}
                  />
                </div>
                <div className="bottomDiv1">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <div className="card-title-main">
                      Toyota Spektakuler
                    </div>
                    <div className="card-para-main">
                      Beli Avanza, Agya, Veloz, atau Rush dan dapatkan gratis paket
                      suku cadang Rp 2.6 Juta, asuransi 2 tahun Rp 16 Juta, dan
                      paket T-Care. Cek Promo Toyota Spektakuler sekarang!
                    </div>
                    <div>
                      <Link to='/toyotaspec'>
                        <button className="box-2-button">READ MORE</button>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>
              <div className="bottomMainDiv1">
                <div className="bottomDiv2">
                  <img
                    style={{ width: "100%", height: "22rem" }}
                    src={EZDealSmall}
                  />
                </div>
                <div className="bottomDiv1">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <div className="card-title-main">
                      EZDeal
                    </div>
                    <div className="card-para-main">
                      Program finansial dari Toyota yang Bikin kamu nggak perlu nunggu lagi untuk punya mobil keren dan jadi makin kece!
                    </div>
                    <div>
                      <Link to='/ezdeal'>
                        <button className="box-2-button">READ MORE</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div> */}
        </Container>





      </div>
      <div className="bottom-button">
        <hr />
        <button onClick={scrollToTop}>
          Back to top <FontAwesomeIcon icon={faAngleUp} />
        </button>
      </div>
      <hr />

      <Footer />
    </>
  );
};

export default Kinto;
