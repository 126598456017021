import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import car from '../../../assets/image/car.png'
import './MPVStyle.css'
import { BASE_URL, Token } from '../../../Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'



export default function Mpv({ data }) {
    console.log("props", data,)

    // const [productList, setProductList] = useState([])
    // useEffect(() => {
    //     handleProductList()
    // })

    // const handleProductList = async () => {
    //     try {


    //         const response = await fetch(`${BASE_URL}/api/frontend/products?category=${keyss}`, {
    //             headers: {

    //                 Authorization: `Bearer ${Token}`,


    //             }
    //         });
    //         const data = await response.json();
    //         console.log("published-product", data);
    //         setProductList(data)






    //     } catch (error) {
    //         console.error('Error fetching buyer data:', error);
    //     }

    // }


    return (
        <>
            {data && data?.length > 0 ? (
                <Row className='text-center mx-auto mpv-dynamic-margin '>
                    {data?.map((item, index) => {

                        let formattedValue = (item.price / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        console.log(formattedValue);
                        return (
                            <>
                                <Col lg={3} md={6} sm={12} className='' style={{ display: 'flex', justifyContent: 'center' }} >
                                    <Card style={{ width: '18rem', border: 'none' }}>


                                        {/* <Card.Img variant="top" src={item.image}  /> */}
                                        <div style={{ overflow: 'hidden', position: 'relative', paddingTop: '5%', paddingBottom: '2%' }}>
                                            <Card.Img
                                                variant="top"
                                                src={item.image}
                                                style={{
                                                    transition: 'transform 0.3s',

                                                    height: 'auto',
                                                }}
                                                onMouseOver={(e) => {
                                                    e.currentTarget.style.transform = 'scale(1.2)';
                                                }}
                                                onMouseOut={(e) => {
                                                    e.currentTarget.style.transform = 'scale(1)';
                                                }}
                                                className='image-dynanmic-size'
                                            />
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ textAlign: 'left', fontWeight: '900', fontSize: '15px' }}>{item.name}</Card.Title>
                                            <Card.Text style={{ textAlign: 'left', fontWeight: '500', fontSize: '13px' }}>
                                                Starting from <span className='span-text'>Rp {formattedValue}</span>
                                            </Card.Text>
                                            <Card.Text className='card-bottom'>
                                                Get Your Offer <FontAwesomeIcon icon={faAngleRight} />
                                            </Card.Text>

                                        </Card.Body>
                                    </Card>

                                </Col>
                            </>
                        )
                    })}





                </Row>
            ) : (
                "no data is found"
            )
            }


            {/* <Row className='text-center mx-auto mt-5'>
                <Col lg={3} md={6} sm={12} className='pb-5' style={{ display: 'flex', justifyContent: 'center' }} >
                    <Card style={{ width: '18rem', border: 'none' }}>

                        <div class="containerrararar">
                            <div class="pentagon-1">
                            </div>

                            <span className='pemtagon-span'>
                                New!
                            </span>


                        </div>


                        <Card.Img variant="top" src={car} />
                        <Card.Body>
                            <Card.Title style={{ textAlign: 'left' }}>All New Alphard</Card.Title>
                            <Card.Text style={{ textAlign: 'left', fontWeight: '600' }}>
                                Starting from <span style={{ fontWeight: '800' }}>Rp 1.356.100.000</span>
                            </Card.Text>

                        </Card.Body>
                    </Card>

                </Col>
                <Col lg={3} md={6} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{ width: '18rem', border: 'none' }}>
                        <Card.Img variant="top" src={car} />
                        <Card.Body>
                            <Card.Title style={{ textAlign: 'left' }}>All New Alphard</Card.Title>

                            <Card.Text style={{ textAlign: 'left', fontWeight: '600' }}>
                                Starting from <span style={{ fontWeight: '800' }}>Rp 1.356.100.000</span>
                            </Card.Text>
                        </Card.Body>
                    </Card>

                </Col>
                <Col lg={3} md={6} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{ width: '18rem', border: 'none' }}>
                        <Card.Img variant="top" src={car} />
                        <Card.Body>
                            <Card.Title style={{ textAlign: 'left' }}>All New Alphard</Card.Title>
                            <Card.Text style={{ fontWeight: '600', textAlign: 'left' }}>
                                Starting from <span style={{ fontWeight: '800' }}>Rp 1.356.100.000</span>
                            </Card.Text>

                        </Card.Body>
                    </Card>

                </Col>
                <Col lg={3} md={6} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{ width: '18rem', border: 'none' }}>
                        <Card.Img variant="top" src={car} />
                        <Card.Body>
                            <Card.Title style={{ textAlign: 'left' }}>All New Alphard</Card.Title>
                            <Card.Text style={{ fontWeight: '600', textAlign: 'left' }}>
                                Starting from <span style={{ fontWeight: '800' }}>Rp 1.356.100.000</span>
                            </Card.Text>

                        </Card.Body>
                    </Card>

                </Col>
                <Col lg={3} md={6} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{ width: '18rem', border: 'none' }}>
                        <Card.Img variant="top" src={car} />
                        <Card.Body>
                            <Card.Title style={{ textAlign: 'left' }}>All New Alphard</Card.Title>
                            <Card.Text style={{ fontWeight: '600', textAlign: 'left' }}>
                                Starting from <span style={{ fontWeight: '800' }}>Rp 1.356.100.000</span>
                            </Card.Text>


                        </Card.Body>
                    </Card>

                </Col>
                <Col lg={3} md={6} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{ width: '18rem', border: 'none' }}>
                        <Card.Img variant="top" src={car} />
                        <Card.Body>
                            <Card.Title style={{ textAlign: 'left' }}>All New Alphard</Card.Title>

                            <Card.Text style={{ fontWeight: '600', textAlign: 'left' }}>
                                Starting from <span style={{ fontWeight: '800' }}>Rp 1.356.100.000</span>
                            </Card.Text>
                        </Card.Body>
                    </Card>

                </Col>
                <Col lg={3} md={6} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{ width: '18rem', border: 'none' }}>
                        <Card.Img variant="top" src={car} />
                        <Card.Body>
                            <Card.Title style={{ textAlign: 'left' }}>All New Alphard</Card.Title>
                            <Card.Text style={{ fontWeight: '600', textAlign: 'left' }}>
                                Starting from <span style={{ fontWeight: '800' }}>Rp 1.356.100.000</span>
                            </Card.Text>

                        </Card.Body>
                    </Card>

                </Col>
                <Col lg={3} md={6} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{ width: '18rem', border: 'none' }}>
                        <Card.Img variant="top" src={car} />
                        <Card.Body>
                            <Card.Title style={{ textAlign: 'left' }}>All New Alphard</Card.Title>

                            <Card.Text style={{ fontWeight: '600', textAlign: 'left' }}>
                                Starting from <span style={{ fontWeight: '800' }}>Rp 1.356.100.000</span>
                            </Card.Text>
                        </Card.Body>
                    </Card>

                </Col>

            </Row> */}



        </>
    )
}
