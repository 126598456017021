import React, { useState, useRef } from "react";
import { Navbar, Container, Nav, Button, Offcanvas, NavDropdown, Form, Dropdown } from 'react-bootstrap'
import logo from '../../logo.svg';
import logod from '../../assets/image/logo.png'
import brandWhite from '../../assets/image/nav-white.png'
import { faCircleXmark, faFilm, faCaretDown, faSmile, faClock, faAngleDown, faCalendar, faBolt, faCircleInfo, faSortDown, faCaretUp, faWandMagicSparkles, faForwardFast, faMugHot, faMinimize, faUserTie, faMaximize, faSpellCheck, faHandFist, faHandBackFist, faMugSaucer, faCoffee, faFileMedical, faMagnifyingGlass, faLocationDot, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import './headerStyle.css'
import Promo from '../../assets/image/slider.png'
import promo2 from '../../assets/image/slider2.png'
import promo3 from "../../assets/image/slider3.png";

import mobilePromo from '../../assets/image/spectaBanner_mobile.png'
import mobilePromo2 from '../../assets/image/mobslider2.png'
import mobilePromo3 from '../../assets/image/mobslider3.png'


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



export default function HeaderTrans() {
    const location = useLocation()
    console.log("loc==>", location.pathname);
    const currentPath = location.pathname

    const [show, setShow] = React.useState(1);
    const [productCate, setProductCate] = useState([])
    const [productList, setProductList] = useState([])


    const data = ['fgccfg', 'ghvgvc', 'gvg', 'jjj', 'kkk', 'lll'];

    const [showButton, setShowButton] = useState(false);


    const settings = {
        // dots: true,
        // infinite: true,
        // speed: 500,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        // swipeToSlide: true,
        // variableWidth: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: false, // Set this to false
        // responsive: [
        //     {
        //         breakpoint: 767, 
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1,
        //         },
        //     },
        // ],
    };


    const containerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const scrollLeft = containerRef.current.scrollLeft;
        setScrollPosition(scrollLeft);
    };

    const handleSwipe = (direction) => {
        const container = containerRef.current;
        const scrollWidth = container.scrollWidth;
        const containerWidth = container.clientWidth;

        const newPosition =
            direction === "left"
                ? Math.max(scrollPosition - containerWidth, 0)
                : Math.min(scrollPosition + containerWidth, scrollWidth - containerWidth);

        container.scrollTo({ left: newPosition, behavior: "smooth" });
        setScrollPosition(newPosition);
    };


    // const renderPaginationDots = () => {
    //     const container = containerRef.current;
    //     const pageCount = Math.ceil(container?.scrollWidth / container?.clientWidth);
    //     const dots = [];

    //     console.log("scsa", pageCount)

    //     for (let i = 0; i < pageCount; i++) {
    //         dots.push(
    //             <div
    //                 key={i}
    //                 className={`pagination-dot ${i * container.clientWidth === scrollPosition ? "active" : ""}`}
    //                 onClick={() => handleDotClick(i)}
    //             ></div>
    //         );
    //     }

    //     return dots;
    // };


    const renderPaginationDots = () => {
        const container = containerRef.current;
        const containerWidth = container?.clientWidth;
        const scrollWidth = container?.scrollWidth - containerWidth + 1; // Add 1 to ensure proper rounding

        const pageCount = Math.ceil(scrollWidth / containerWidth);
        const dots = [];

        for (let i = 0; i < pageCount; i++) {
            dots.push(
                <div
                    key={i}
                    className={`pagination-dot ${Math.round(i * containerWidth) === scrollPosition ? "active" : ""}`}
                    onClick={() => handleDotClick(i)}
                ></div>
            );
        }

        return dots;
    };


    const handleDotClick = (index) => {
        const container = containerRef.current;
        const newPosition = index * container.clientWidth;

        container.scrollTo({ left: newPosition, behavior: "smooth" });
        setScrollPosition(newPosition);
    };




    return (
        <>
            <header>
                {/* <img src={Promo} className="image-landing-2" /> */}
                <div className="web-display">
                    <div
                        style={{
                            width: "100%",
                            padding: '0px 5px',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        {show === 1 && <div style={{ position: "relative", width: '100%' }} className="">
                            <Link to="/toyotaspec">

                                <img
                                    style={{ width: "100%" }}
                                    //  src={Kinto1}
                                    src={Promo}
                                />
                            </Link>
                        </div>}
                        {show === 2 && <div style={{ position: "relative", width: '100%' }} className="">
                            <Link to="/ezdeal">
                                <img
                                    style={{ width: "100%" }}
                                    //  src={Kinto1}
                                    src={promo3}

                                />
                            </Link>
                        </div>}
                        {show === 3 && <div style={{ position: "relative", width: '100%' }} className="">


                            <Link to="/kinto">
                                <img
                                    style={{ width: "100%" }}
                                    //  src={Kinto1}
                                    src={promo2}
                                />
                            </Link>
                        </div>}

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0px 20px",
                                position: "absolute",
                            }}
                        >
                            <div className="LineDiv">
                                <div
                                    onClick={() => setShow(1)}
                                    className={show === 1 ? "orangeLine" : "grayDot"}
                                >
                                    <div className={show === 1 ? "orangeLine-2" : "grayDot"}>

                                    </div>

                                </div>
                                <div
                                    onClick={() => setShow(2)}
                                    className={show === 2 ? "orangeLine" : "grayDot"}
                                >
                                    <div className={show === 2 ? "orangeLine-2" : "grayDot"}>

                                    </div>
                                </div>
                                <div
                                    onClick={() => setShow(3)}
                                    className={show === 3 ? "orangeLine" : "grayDot"}
                                >
                                    <div className={show === 3 ? "orangeLine-2" : "grayDot"}>

                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    style={{ marginBottom: "10px" }}
                                    onClick={() => setShow(show === 1 ? 3 : show - 1)}
                                    className={show === 1 ? "sliderCircleDull" : "sliderCircle"}
                                >
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                </div>
                                <div
                                    onClick={() => setShow(show === 3 ? 1 : show + 1)}
                                    className={show === 3 ? "sliderCircleDull" : "sliderCircle"}
                                >
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="mobile-display">





                    <div
                        ref={containerRef}
                        className="scroll-container"
                        onScroll={handleScroll}
                    >


                        <img style={{ width: "100%" }} src={mobilePromo} alt="Mobile Promo 1" />
                        <img style={{ width: "100%" }} src={mobilePromo2} alt="Mobile Promo 2" />
                        <img style={{ width: "100%" }} src={mobilePromo3} alt="Mobile Promo 3" />
                    </div>
                    <div className="pagination-dots">{renderPaginationDots()}</div>
                </div>




                {['lg'].map((expand) => (
                    <Navbar key={expand} bg="dark" expand={expand} className=" babababa">
                        <div className="container-fluid" >
                            <Navbar.Brand href="#" className="navbar-brand"><img src={brandWhite} /></Navbar.Brand>
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="start"
                                style={{ backgroundColor: 'black' }}
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                        <img src={brandWhite} className="side-bar-logo"></img>


                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    {/* <Nav className="justify-content-center flex-grow-1 pe-3"> */}
                                    <Nav className="jus flex-grow-1 pe-3">
                                        <Nav.Link >
                                            <Link to="/toyotaspec" className={currentPath === "/toyotaspec" ? "active-link-text" : "link-text"} style={{ color: currentPath === "/toyotaspec" ? 'blue' : 'white' }}>
                                                Toyota Electrified
                                            </Link>
                                        </Nav.Link>
                                        <Nav.Link href="/#/ezdeal">
                                            <Link to="/ezdeal" className={currentPath === "/ezdeal" ? "active-link-text" : "link-text"} style={{ color: currentPath === "/ezdeal" ? 'blue' : 'white' }}>
                                                Gazoo Racing
                                            </Link>
                                        </Nav.Link>

                                        {/* <NavDropdown
                                            title="Models"
                                            id={`offcanvasNavbarDropdown-expand-${expand}`}


                                        >

                                            <NavDropdown.Item>
                                                <Link to="/kinto" className={currentPath === "/kinto" ? "active-link-text" : "link-text-2"}>
                                                    Kinto
                                                </Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item >
                                                Something else here
                                            </NavDropdown.Item>
                                        </NavDropdown> */}

                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ color: 'white' }} >
                                                Models
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item >
                                                    <Link to="/kinto" className={currentPath === "/kinto" ? "active-link-text" : "link-text-2"}>
                                                        Kinto
                                                    </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ color: 'white' }} >
                                                Shopping Tools
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item >
                                                    <Link to="/kinto" className={currentPath === "/kinto" ? "active-link-text" : "link-text-2"}>
                                                        Kinto
                                                    </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>



                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ color: 'white' }} >
                                                Promotion
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item >
                                                    <Link to="/kinto" className={currentPath === "/kinto" ? "active-link-text" : "link-text-2"}>
                                                        Kinto
                                                    </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>


                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ color: 'white' }} >
                                                Ownership
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item >
                                                    <Link to="/kinto" className={currentPath === "/kinto" ? "active-link-text" : "link-text-2"}>
                                                        Kinto
                                                    </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>


                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ color: 'white' }} >
                                                More
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item >
                                                    <Link to="/kinto" className={currentPath === "/kinto" ? "active-link-text" : "link-text-2"}>
                                                        Kinto
                                                    </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>





                                        {/* <NavDropdown
                                            title="Shopping Tools"
                                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                                        >
                                            <NavDropdown.Item ></NavDropdown.Item>
                                            <NavDropdown.Item >
                                                Another action
                                            </NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item >
                                                Something else here
                                            </NavDropdown.Item>
                                        </NavDropdown> */}

                                        {/* <NavDropdown
                                            title="Ownership"
                                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                                        >
                                            <NavDropdown.Item ></NavDropdown.Item>
                                            <NavDropdown.Item >
                                                Another action
                                            </NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item >
                                                Something else here
                                            </NavDropdown.Item>
                                        </NavDropdown> */}

                                        {/* <NavDropdown
                                            title="Promotion"
                                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                                        >
                                            <NavDropdown.Item ></NavDropdown.Item>
                                            <NavDropdown.Item >
                                                Another action
                                            </NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item >
                                                Something else here
                                            </NavDropdown.Item>
                                        </NavDropdown> */}

                                        {/* <NavDropdown
                                            title="More"
                                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                                        >
                                            <NavDropdown.Item ></NavDropdown.Item>
                                            <NavDropdown.Item >
                                                Another action
                                            </NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item >
                                                Something else here
                                            </NavDropdown.Item>
                                        </NavDropdown> */}


                                    </Nav>

                                    <Nav className="justify-content-end flex-grow-1 pe-3">
                                        <Nav.Link>   <FontAwesomeIcon icon={faMagnifyingGlass} className="icon-nav" /></Nav.Link>
                                        <Nav.Link>   <FontAwesomeIcon icon={faLocationDot} className="icon-nav" /></Nav.Link>
                                    </Nav>




                                </Offcanvas.Body>

                            </Navbar.Offcanvas>
                            {/* <Navbar.Brand href="#" className="navbar-brand">
                                <img src={logo} className="navbar-brand-logo"></img>
                            </Navbar.Brand> */}

                        </div >
                    </Navbar>
                ))}

            </header>
        </>

    )
}





