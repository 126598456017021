import logo from './logo.svg';
import './App.css';
import Header from './view/header';
import LinearSearch from './component/test/LinearSearch';
import HomePage from './view/pages/homepage';
import Footer from './view/footer';
import PageRouter from './view/router/Router';




function App() {
  return (
    <div className="App">

      <PageRouter />




    </div>
  );
}

export default App;
